<template>
    <div class="bkColor">
        <headTitle :title="title + '支出明细'" :routerPath="routerPath"></headTitle>
        <van-search v-model="value" input-align="center" placeholder="请输入搜索关键词" shape="round" show-action @search="onSearch"
            @cancel="onCancel" style="margin-bottom:2vh" />
        <div class="search">
            <!-- <div>类型：</div> -->
            <div style="display:flex; justify-content: space-around;align-items: center;">
                <!-- <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.type" :options="typeOption" @change="changeType" />
                </van-dropdown-menu> -->
                <div class="searchTitle">归属：</div>
                <van-dropdown-menu>
                    <van-dropdown-item v-model="queryFrom.belong" :options="belongOption" @change="changeBelong" />
                </van-dropdown-menu>
            </div>
        </div>
        <div class="list">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished" :error.sync="error" error-text="请求失败，点击重新加载"
                    finished-text="没有更多了" @load="getDataDeatailsList">
                    <div class="listCell" v-for="(item, index) in list" :key="index" @click="goDetails(item.id, item.type)">
                        <van-swipe-cell>
                            <!-- <div class="cellName">{{ item.projectName ? item.projectName : item.userName + "的" +
                                title + (item.type == 1 ? '付款' : '报销') }}
                            </div>
                            <div class="cellText"> 流水号：{{ item.number }}</div>
                            <div class="cellText">详细说明：<div style="width:40%;display: inline-block;  vertical-align: top;">
                                    {{ item.description }}
                                </div>
                            </div>
                            <div class="cellText">交易金额：{{ item.price }}元</div>
                            <div class="cellText">创建日期：{{ item.createTime }}</div> -->
                        </van-swipe-cell>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
import { income, expenditure } from '../api/api'
import headTitle from '../components/headTitle.vue'
import Vue from 'vue';
import { Search, Toast, List, PullRefresh, Cell, SwipeCell, Button, Dialog } from 'vant';

Vue.use(Search)
Vue.use(Toast)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(Cell);
Vue.use(SwipeCell)
Vue.use(Button)
Vue.use(Dialog)
export default {
    name: 'dataDetailsList',
    components: {
        headTitle
    },
    data() {
        return {
            title: '',
            value: '',
            queryFrom: {
                belong: null,
                type: null,
                pageSize: 10,
                pageNum: 0,
                vague: ''
            },
            belongOption: [
                { text: '全部', value: null },
                { text: '大美', value: '1' },
                { text: '中创', value: '2' },
                { text: "广云仓", value: '3' },
                { text: "大丰年", value: '4' },
            ],
            // typeOption: [
            //     { text: '全部', value: null },
            //     { text: "付款", value: 1 },
            //     { text: "报销", value: 2 },
            // ],
            routerPath: '/dataAnalysis',
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
            error: false,
            type: ''
        }
    },
    mounted() {
        this.type = this.$route.query.type
        this.getGuaranteeDeatailsList()
    },
    methods: {
        getGuaranteeDeatailsList() {
            this.loading = true
            if (this.type == 'in') {
                income().then(res => {
                    this.list = this.queryFrom.pageNum == 1 ? res.data.rows : this.list.concat(res.data.rows)
                    this.refreshing = false
                    this.loading = false
                    this.queryFrom.pageNum = this.queryFrom.pageNum + 1
                    if (res.data.total <= (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize) {
                        this.finished = true
                    }
                })
            } else if (this.type == "out") {
                expenditure().then(res => {
                    this.list = this.queryFrom.pageNum == 1 ? res.data.rows : this.list.concat(res.data.rows)
                    this.refreshing = false
                    this.loading = false
                    this.queryFrom.pageNum = this.queryFrom.pageNum + 1
                    if (res.data.total <= (this.queryFrom.pageNum - 1) * this.queryFrom.pageSize) {
                        this.finished = true
                    }
                })
            }
        },
        changeBelong(val) {
            this.queryFrom.belong = val
            this.onRefresh()
        },
        changeType(val) {
            this.queryFrom.type = val
            this.onRefresh()
        },
        onSearch(val) {
            this.queryFrom.vague = val
            this.onRefresh()
        },
        //搜索框取消
        onCancel() {
            this.queryFrom.vague = ''
            this.queryFrom.state = null
            this.queryFrom.isSettle = null
            this.queryFrom.belong = null
            this.onRefresh()
        },
        //下拉刷新
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.queryFrom.pageNum = 1
            this.list = []
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.getDataDeatailsList();
        },
        goDetails(id, type) {
            console.log('/dataDetailsList?type=' + this.queryFrom.statisticsType)
            this.$router.push({
                path: type == 1 ? '/paymentDetails' : '/reimbursementDetails',
                query: {
                    id: id, route: '/dataDetailsList?type=' + this.queryFrom.statisticsType
                }
            })
        }
    }

}
</script>

<style lang="less" scoped>
.bkColor {
    width: 100vw;
    min-height: 100vh;
    background: #F6F6F6;
    position: relative;
}

.search {
    width: 100vw;
    height: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    background: #fff;
    font-size: 13px;
    line-height: 8vh;
}

.searchTitle {
    margin-left: 4vw;
}


/deep/ .van-ellipsis {
    font-size: 13px;
}

.list {
    margin-top: 2vh;
}

.listCell {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0 5vw 1vh;
    background: #fff;
    text-align: left;
}

.cellName {
    width: 90%;
    font-size: 1rem;
    font-weight: 600;
    border-top: 2px solid #F6F6F6;
    padding-top: 1vh;
    padding-bottom: 1vh;

    .status {
        float: right;
        font-size: 0.8rem;
        font-weight: 500;
        text-align: right;
    }
}

/deep/ .van-list :first-of-type.listCell .cellName {
    border-top: unset;
}

.cellText {
    width: 70%;
    color: #797979;
    margin-bottom: 0.5vh;
    font-size: 0.7rem;
    word-break: break-all;
    line-height: 1;
}
</style>